import Script from "next/script";

export default function UmamiAnalyticsScript() {
  /* Add google analytics script if env is prod */
  if (process.env.NODE_ENV === "production") {
    return (
      <Script
        async
        strategy="lazyOnload"
        src="https://analytics.eu.umami.is/script.js"
        data-website-id="cb7fec82-e181-4fd1-96e2-a4948d56382c"
      ></Script>
    );
  } else {
    return null;
  }
}
